import React, { useState } from "react";
import "./Home.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import absfixtwo from "../../assets/images/niku.png-2.png";
import { Autoplay, Scrollbar, Navigation, Mousewheel } from "swiper";
import landingVideo from "../../assets/videos/Govori-landindpg-3.mp4";
import triangles from "../../assets/images/triangleone.svg";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import chair from "../../assets/images/skrinskrin2.png";
import Service from "../../services/general";

import ReactHtmlParser from "react-html-parser";
import Footer from "../../components/Footer/Footer";
import Vimeo from "@u-wave/react-vimeo";
import loadingV from "../../assets/videos/govori-gif.gif";
import Loader from "../../components/loader/Loader";
export default function Home() {
  let slideCounter = 3;
  if (isMobile) {
    slideCounter = 1;
  }
  document.body.style.backgroundColor = "#000";

  const service = new Service();
  const [data, setData] = useState([]);
  const [mainTitle, setMainTitle] = useState([]);
  const [subTitle, setSubTitle] = useState([]);
  const [mainDescription, setMainDescription] = useState([]);
  const [projects, setProjects] = useState([]);
  const [footer, setFooter] = useState([]);

  const [CGI, setCGI] = useState([]);
  const [dev, setDev] = useState([]);
  const [VFX, setVFX] = useState([]);
  const [cgiparagraph, setCGIParagraph] = useState([]);
  const [devparagraph, setDevParagraph] = useState([]);
  const [vfxparagraph, setVFXParagraph] = useState([]);
  const [videoBanner, setVideoBanner] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [loading, setLoading] = useState(true);
  const homeData = () => {
    service
      .getHome()
      .then((res) => {
        setMainTitle(res.data[0].acf.content[0].title);
        setSubTitle(res.data[0].acf.content[0].second_subtitle);
        setMainDescription(res.data[0].acf.content[0].bottom_description);
        setVideoBanner(res.data[0].acf.content[0].video_url);
      })
      .catch((e) => console.log(e));
  };
  const projectData = () => {
    service
      .getProjectHome()
      .then((res) => {
        setProjects(res.data);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setData(homeData);
    setProjects(projectData);
  }, []);

  const serviceData = () => {
    service
      .getServices()
      .then((res) => {
        setCGI(res.data[0].acf.content[0].services[0].group);
        setDev(res.data[0].acf.content[0].services[1].group);
        setVFX(res.data[0].acf.content[0].services[2].group);
        setCGIParagraph(res.data[0].acf.content[0].services[0].service);
        setDevParagraph(res.data[0].acf.content[0].services[1].service);
        setVFXParagraph(res.data[0].acf.content[0].services[2].service);
      })

      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setData(serviceData);
    setTimeout(() => {
      setShowVideo(true);
    }, 1000);
  }, []);

  return (
    <div className="home">
      {loading && <Loader></Loader>}
      <div className="home-landing section">
        <div className="video-start-fix"></div>
        {!isMobile && (
          <video className="videostart" autoPlay="autoplay" muted={true} loop>
            <source src={landingVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}{" "}
        {isMobile && <img className="videostartt" src={chair} alt="" />}
        {/* <div className="abs-fix">
        <img src={absfixone} alt="" />
      </div> */}
        <div className="move-in-video">
          <p className="quality-landing">{ReactHtmlParser(mainTitle)}</p>
          <p className="landing-3d">{ReactHtmlParser(subTitle)}</p>
          <p className="we-are">{ReactHtmlParser(mainDescription)}</p>
        </div>
      </div>
      <div className="triangle-part section">
        <img className="absfixtwo" src={absfixtwo} alt="" />
        {/* <img className="left-side" src={leftSide} alt="" />
        <img className="right-side" src={rightSide} alt="" /> */}
        <div className="triangles">
          <img src={triangles} alt="" id="first-triangle" />
          <img src={triangles} alt="" id="second-triangle" />
          <img src={triangles} alt="" id="third-triangle" />
          <img src={triangles} alt="" id="fourth-triangle" />
          <img src={triangles} alt="" id="fifth-triangle" />
        </div>
        <div className="two-parts">
          <div id="cgi" onMouseOver={hoverCGI} onMouseLeave={unhoverCGI}>
            <p className="cgi">
              {" "}
              <div className="plusi" id="plusicgi">
                <p>+</p>
              </div>{" "}
              {CGI}
            </p>
            <div className="text-triangle">
              {cgiparagraph &&
                cgiparagraph.map((cgiis) => (
                  <p className="hover-one-by-one-cgi">{cgiis.title}</p>
                ))}
            </div>
          </div>
          <div
            id="coding"
            onMouseOver={hoverCODING}
            onMouseLeave={unhoverCODING}
          >
            <p className="coding">
              {" "}
              <div className="plusi" id="plusicoding">
                <p>+</p>
              </div>{" "}
              {dev}
            </p>
            <div className="text-triangle">
              {devparagraph &&
                devparagraph.map((devis) => (
                  <p className="hover-one-by-one-coding">{devis.title}</p>
                ))}
            </div>
          </div>
        </div>
        <div id="vfx" onMouseOver={hoverVFX} onMouseLeave={unhoverVFX}>
          <p className="vfx">
            {" "}
            <div className="plusi" id="plusivfx">
              <p>+</p>
            </div>{" "}
            {VFX}
          </p>
          <div className="text-triangle">
            {vfxparagraph &&
              vfxparagraph.map((vfxis) => (
                <p className="hover-one-by-one-vfx">{vfxis.title}</p>
              ))}
          </div>
        </div>

        <div className="swiper-button-next"></div>
      </div>
      <div className="project-part section">
        <Link to="/projects">
          <div className="projects-underline">
            <p className="projects-intro-header">Projects</p>
          </div>
        </Link>

        <Swiper
          slidesPerView={slideCounter}
          spaceBetween={30}
          slidesPerGroup={slideCounter}
          autoplay={{
            delay: 80900,
            disableOnInteraction: false,
          }}
          loop={false}
          loopFillGroupWithBlank={true}
          navigation={true}
          mousewheel={false}
          scrollbar={{
            hide: true,
          }}
          modules={[Navigation, Autoplay, Scrollbar, Mousewheel]}
          className="mySwiper marginfixt overflowfix"
        >
          <div className="black-left"></div>
          <div className="black-right"></div>
          {projects &&
            projects.map((project) => (
              <SwiperSlide className="slides">
                <Link to={"/single-project/" + project.id}>
                  <img src={project.acf.banner} alt="" />
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      {showVideo && (
        <div className="video-container section">
          <Vimeo
            className="video-banner-home"
            video="https://vimeo.com/426312680/c0f7970de3"
            autoplay={true}
            controls={false}
            volume={0}
            start={0}
            loop={true}
          ></Vimeo>
        </div>
      )}
      <div className="swiper-wrapper-nk"></div>
      <div className="section footeri-home">
        <Footer></Footer>
      </div>
    </div>
  );

  function hoverCGI() {
    setTimeout(() => {
      document.getElementById("first-triangle").style.transform =
        "rotate(-5deg)";
    }, 200);
    setTimeout(() => {
      document.getElementById("second-triangle").style.transform =
        "rotate(-12deg)";
    }, 400);
    setTimeout(() => {
      document.getElementById("third-triangle").style.transform =
        "rotate(8deg)";
    }, 600);
    setTimeout(() => {
      document.getElementById("fourth-triangle").style.transform =
        "rotate(-12deg)";
    }, 800);
    setTimeout(() => {
      document.getElementById("fifth-triangle").style.transform =
        "rotate(6deg)";
    }, 1000);
    document.getElementById("plusicgi").style.backgroundColor = "#2EC4B6";
    document.getElementById("plusicgi").style.boxShadow =
      "10px 10px 142px 52px rgba(46,196,182,0.25)";
    const elposcgi = document.querySelectorAll(".hover-one-by-one-cgi");
    let lengthposcgi = elposcgi.length;
    let timeoutposcgi = 500;
    for (let indexposcgi = 0; indexposcgi < lengthposcgi; indexposcgi++) {
      setTimeout(() => {
        elposcgi[indexposcgi].classList.add("marginleftzero");
      }, timeoutposcgi);
      timeoutposcgi = timeoutposcgi + 300;
    }
  }
  function unhovermovetriangle() {
    setTimeout(() => {
      document.getElementById("first-triangle").style.transform =
        "rotate(-5deg)";
    }, 200);
    setTimeout(() => {
      document.getElementById("second-triangle").style.transform =
        "rotate(-10deg)";
    }, 400);
    setTimeout(() => {
      document.getElementById("third-triangle").style.transform =
        "rotate(-8deg)";
    }, 600);
    setTimeout(() => {
      document.getElementById("fourth-triangle").style.transform =
        "rotate(-6deg)";
    }, 800);
    setTimeout(() => {
      document.getElementById("fifth-triangle").style.transform =
        "rotate(-10deg)";
    }, 1000);
  }
  function unhoverVFX() {
    unhovermovetriangle();
    document.getElementById("plusivfx").style.backgroundColor = "transparent";
    document.getElementById("plusivfx").style.boxShadow =
      "0px 0px 0px 0px rgba(46,196,182,0.25)";
    const elvfx = document.querySelectorAll(".hover-one-by-one-vfx");

    let lengthvfx = elvfx.length;
    let timevfx = 2000;
    for (let indexvfx = lengthvfx; indexvfx > -1; indexvfx--) {
      setTimeout(() => {
        elvfx[indexvfx].classList.remove("marginleftzero");
      }, timevfx);
      timevfx = timevfx + 300;
    }
  }
  function unhoverCODING() {
    unhovermovetriangle();
    document.getElementById("plusicoding").style.backgroundColor =
      "transparent";
    document.getElementById("plusicoding").style.boxShadow =
      "0px 0px 0px 0px rgba(46,196,182,0.25)";
    const elcoding = document.querySelectorAll(".hover-one-by-one-coding");

    let lengthcoding = elcoding.length;
    let timecoding = 2000;
    for (let indexcoding = lengthcoding; indexcoding > -1; indexcoding--) {
      setTimeout(() => {
        elcoding[indexcoding].classList.remove("marginleftzero");
      }, timecoding);
      timecoding = timecoding + 300;
    }
  }
  function unhoverCGI() {
    unhovermovetriangle();
    document.getElementById("plusicgi").style.backgroundColor = "transparent";
    document.getElementById("plusicgi").style.boxShadow =
      "0px 0px 0px 0px rgba(46,196,182,0.25)";

    const elcgi = document.querySelectorAll(".hover-one-by-one-cgi");

    let lengthcgi = elcgi.length;
    let timecgi = 2000;
    for (let indexcgi = lengthcgi; indexcgi > -1; indexcgi--) {
      setTimeout(() => {
        elcgi[indexcgi].classList.remove("marginleftzero");
      }, timecgi);
      timecgi = timecgi + 300;
    }
  }
  function hoverVFX() {
    setTimeout(() => {
      document.getElementById("first-triangle").style.transform =
        "rotate(-105deg)";
    }, 200);
    setTimeout(() => {
      document.getElementById("second-triangle").style.transform =
        "rotate(-95deg)";
    }, 400);
    setTimeout(() => {
      document.getElementById("third-triangle").style.transform =
        "rotate(-90deg)";
    }, 600);
    setTimeout(() => {
      document.getElementById("fourth-triangle").style.transform =
        "rotate(-105deg)";
    }, 800);
    setTimeout(() => {
      document.getElementById("fifth-triangle").style.transform =
        "rotate(-90deg)";
    }, 1000);
    setTimeout(() => {}, 3000);
    document.getElementById("plusivfx").style.backgroundColor = "#2EC4B6";
    document.getElementById("plusivfx").style.boxShadow =
      "10px 10px 142px 52px rgba(46,196,182,0.25)";
    const elposvfx = document.querySelectorAll(".hover-one-by-one-vfx");
    let lengthposvfx = elposvfx.length;
    let timeoutposvfx = 500;
    for (let indexposvfx = 0; indexposvfx < lengthposvfx; indexposvfx++) {
      setTimeout(() => {
        elposvfx[indexposvfx].classList.add("marginleftzero");
      }, timeoutposvfx);
      timeoutposvfx = timeoutposvfx + 300;
    }
  }
  function hoverCODING() {
    setTimeout(() => {
      document.getElementById("first-triangle").style.transform =
        "rotate(140deg)";
    }, 200);
    setTimeout(() => {
      document.getElementById("second-triangle").style.transform =
        "rotate(135deg)";
    }, 400);
    setTimeout(() => {
      document.getElementById("third-triangle").style.transform =
        "rotate(145deg)";
    }, 600);
    setTimeout(() => {
      document.getElementById("fourth-triangle").style.transform =
        "rotate(135deg)";
    }, 800);
    setTimeout(() => {
      document.getElementById("fifth-triangle").style.transform =
        "rotate(150deg)";
    }, 1000);
    document.getElementById("plusicoding").style.backgroundColor = "#2EC4B6";
    document.getElementById("plusicoding").style.boxShadow =
      "10px 10px 142px 52px rgba(46,196,182,0.25)";
    const elposcoding = document.querySelectorAll(".hover-one-by-one-coding");
    let lengthposcoding = elposcoding.length;
    let timeoutposcoding = 500;
    for (
      let indesposcoding = 0;
      indesposcoding < lengthposcoding;
      indesposcoding++
    ) {
      setTimeout(() => {
        elposcoding[indesposcoding].classList.add("marginleftzero");
      }, timeoutposcoding);
      timeoutposcoding = timeoutposcoding + 300;
    }
  }
}
