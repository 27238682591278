import React from "react";
import cursorit from "../../assets/images/Suiii2.png";
import cursoriPika from "../../assets/images/cursorDot.png";
import cursorHija from "../../assets/images/hija2.png";

import "./Cursor.scss";

const Cursor = () => {
  setTimeout(() => {
    var cursori = document.getElementById("cursori");
    var cursorihija = document.getElementById("cursoriHija");

    document.addEventListener("mousemove", function (e) {
      var x = e.clientX;
      var y = e.clientY;
      cursori.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
      cursorihija.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
    });
  }, 100);

  return (
    <div>
      <img src={cursoriPika} id="cursori" class="cursori"></img>
      <img src={cursorHija} id="cursoriHija" alt="" />
    </div>
  );
};

export default Cursor;
