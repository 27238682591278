import React from 'react';
import Service from '../../services/general';
import "./Footer.scss"
import ReactHtmlParser from 'react-html-parser';
import { useState } from 'react';
import leavingVideo from "../../assets/videos/cotact-video3.mp4";
import { useEffect } from 'react';
import instagramimg from "../../assets/images/instagram.svg"
import linkedinimg from "../../assets/images/linkedin.svg"
import facebookimg from "../../assets/images/facebook.svg"
import { isMobile } from 'react-device-detect';
import footerPhoto from "../../assets/images/footerPhoto.png"
const Footer = () => {
    const service = new Service();
    const [footer, setFooter] = useState([]);
    const [footerTitle, setFooterTitle] = useState([]);
    const [address, setFooterAddress] = useState([]);
    const [tel,setFooterTel] = useState([]);
    const [email,setFooterEmail] = useState([]);
    const [instagram, setInstagram] = useState([]);
    const [facebook,setFacebook] = useState([]);
    const [linkedin,setLinkedin] = useState([]);
    const footerData = () => {
        service
          .getFooter()
          .then((res) => {
            let data = res.data[0]
            setFooterTitle(res.data[0].acf.title);
            setFooterAddress(data.acf.address)
            setFooterTel(data.acf.tel)
            setFooterEmail(data.acf.email)
            setInstagram(data.acf.socials[1].link)
            setFacebook(data.acf.socials[0].link)
            setLinkedin(data.acf.socials[3].link)

          })
          .catch((e) => console.log(e));
      };
      useEffect(() => {
     
        setFooter(footerData);
      }, []);
    
  
    return (
        
        <div>
             <div className="contact-home" id="contact">
       {!isMobile && <video className="video-footer" autoPlay="autoplay" muted={true} loop>
          <source src={leavingVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video> }
      {isMobile &&
       <img className='photo-footer' src={footerPhoto}/>
      }
        <p className="header-contact">{ReactHtmlParser(footerTitle)}</p>
        <div className="sections-contact">
          <div className="section-contact">
            <p className="section-blue">GOVORI STUDIOS </p>
            <div className="contact-number">{ReactHtmlParser(address)}</div>
           
          </div>  <div className="section-contact">
            <a href={linkedin}> 
            <img className='social-icons' src={linkedinimg} alt="" />      
            </a>
            <a href={facebook}>       
                 <img className='social-icons' src={facebookimg} alt="" />
            </a>    
            <a href={instagram}>      
            <img className='social-icons' src={instagramimg} alt="" /> 
            </a>    
                  </div>
          <div className="section-contact">

            <div className="contact-number margin-footer">{ReactHtmlParser(tel)}</div>
            <div className="email">{ReactHtmlParser(email)}</div>
          </div>
        
                  
        </div>
      </div>
        </div>
    );
}

export default Footer;
