import React from 'react';
import loadingV from "../../assets/videos/govori-gif.gif"
import "./Loader.scss"
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
const Loader = () => {


    return (
      <div>
           
      <img src={loadingV} id="videoLoad" alt="" />
        </div>
    );
  
}


export default Loader;
