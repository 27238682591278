import React from 'react';
import "./About.scss"
import aboutVideo from "../../assets/videos/Govori-about us-dark-1.mp4"
import Service from '../../services/general';
import { useEffect, useState } from 'react';
import aboutskrinskrin from "../../assets/images/aboutskrinskrin2.png"
import { isMobile } from "react-device-detect";
import ReactHtmlParser from 'react-html-parser';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/loader/Loader';
const About = () => {
    const service = new Service();
    const [data, setData] = useState([]);
    const [aboutTitle, setAboutTitle] = useState([]);
    const [aboutDesc, setAboutDesc] = useState([]);
    const [loading, setLoading] = useState(true);


  const aboutData = () => {
    service
      .getAbout()
      .then((res) => {
        setAboutTitle(res.data[0].title.rendered);
        setAboutDesc(res.data[0].acf.content[0].bottom_description)
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setData(aboutData);



  }, []);
    return (
        <div className='about-page'>
          {loading && <Loader></Loader>}
          <img src={aboutskrinskrin} className="image-about" alt="" />
       {!isMobile &&       <video className="video-about" autoPlay="autoplay" muted={true} loop>
        <source src={aboutVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
}
            <div className="text-about">
                <div className="about-text">
                <p className='about-header'>{ReactHtmlParser(aboutTitle)}</p>
                <p className='about-paragraph'>{ ReactHtmlParser(aboutDesc) }</p>
            </div>
            </div>

            <Footer></Footer>
        </div>
    );
}
export default About;
