import React from "react";
import "./Services.scss";
import landingVideo from "../../assets/videos/start_loop_normal.mp4";
import Service from "../../services/general";
import { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import chair from "../../assets/images/skrinskrin2.png"
import { isMobile } from "react-device-detect";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/loader/Loader";
const Services = () => {
  const service = new Service();
  const [data, setData] = useState([]);
  const [CGI, setCGI] = useState([]);
  const [dev, setDev] = useState([]);
  const [VFX, setVFX] = useState([]);
  const [cgiparagraph, setCGIParagraph] = useState([]);
  const [devparagraph, setDevParagraph] = useState([]);
  const [vfxparagraph, setVFXParagraph] = useState([]);
  const [loading, setLoading] = useState(true);


  const serviceData = () => {
    service
      .getServices()
      .then((res) => {
        setCGI(res.data[0].acf.content[0].services[0].group);
        setDev(res.data[0].acf.content[0].services[1].group);
        setVFX(res.data[0].acf.content[0].services[2].group);
        setCGIParagraph(res.data[0].acf.content[0].services[0].service);
        setDevParagraph(res.data[0].acf.content[0].services[1].service);
        setVFXParagraph(res.data[0].acf.content[0].services[2].service);
        setLoading(false);
      })

      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setData(serviceData);
  }, []);
  return (
    <div className="services-page">
      {loading && <Loader></Loader>}
            <img className="image-services" src={chair} alt="" />

 {!isMobile &&   <video className="video-services" autoPlay="autoplay" muted={true} loop>
        <source src={landingVideo} type="video/mp4" />
      </video>
      }
      <h1 className="services-header">Services</h1>
      <div className="services-wrapper">
        <div className="service">
          <p className="service-title">{CGI}</p>
          <div className="service-paragraphs">
            {cgiparagraph &&
              cgiparagraph.map((cgis) => (
                <p className="service-paragraph"> {ReactHtmlParser(cgis.title)} </p>
              ))}
          </div>
        </div>
        <div className="service">
          <p className="service-title">{dev}</p>
          <div className="service-paragraphs">
            {devparagraph &&
              devparagraph.map((devis) => (
                <p className="service-paragraph">{ReactHtmlParser(devis.title)}</p>
              ))}
          </div>
        </div>
        <div className="service">
          <p className="service-title">{VFX}</p>
          <div className="service-paragraphs">
            {vfxparagraph &&
              vfxparagraph.map((vfxis) => (
                <p className="service-paragraph">{ReactHtmlParser(vfxis.title)}</p>
              ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Services;
