import React from "react";
import "./Single-Project.scss";
import rightArrow from "../../assets/images/right-arrow.svg";
import leavingVideo from "../../assets/videos/start_loop_normal.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Service from "../../services/general";
import { Pagination, EffectCoverflow } from "swiper";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { FALSE } from "sass";
import Masonry from "@mui/lab/Masonry";
import { isMobile } from "react-device-detect";
import Footer from "../../components/Footer/Footer";
import ReactCompareImage from "react-compare-image";
import { useRef } from "react";
import Loader from "../../components/loader/Loader";
const SingleProject = () => {
  const parse = require("html-react-parser");
  const { id } = useParams();
  const service = new Service();
  const [data, setData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [mainTitle, setMainTitle] = useState([]);
  const [subTitle, setSubTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [beforeImage, setBeforeImage] = useState([]);
  const [afterImage, setAfterImage] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [beforeImage2, setBeforeImage2] = useState([]);
  const [afterImage2, setAfterImage2] = useState([]);
  const [children, setChildren] = useState([]);
  const [checkChildren, setCheckChildren] = useState(false);
  const [isGallery, setIsGallery] = useState(false);
  const [isBefore, setIsBefore] = useState(false);
  const [isTwoInRow, setTwoInRow] = useState(false);
  const [content, setContent] = useState([]);
  const [isBanner, setIsBanner] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isSingleBlock, setSingleBlock] = useState(false);
  const [gallerySingle, setGallerySingle] = useState([]);
  const [testGallery, setTestGallery] = useState([]);
  const dataFetchedRef = useRef(false);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(true);

  const [testGalleryData, setTestGalleryData] = useState([]);
  let cols = 2;
  let spacingMasonry = 5;
  if(isMobile){
    cols = 1;
    spacingMasonry = 1;
  }
  const projectData = () => {
    service
      .getProject(id)
      .then((res) => {
        let data = res.data[0];
        let acf = data.acf.content;
        setProjects(res.data);
        setChildren(res.data[0].acf.childrens);

        if (data.acf.has_children) {
          setCheckChildren(true);
        } else {
          setCheckChildren(false);
        }


        for (let i = 0; i < acf.length; i++) {
          if (acf[i].acf_fc_layout === "single_block") {
            setContent(acf);
            setSingleBlock(true);
            setGallerySingle(acf[i].gallery);

            let item = acf[i].gallery;
            setTestGallery((testGallery) => [...testGallery, ...item]);

            let data = acf[i];
            setTestGalleryData((testGalleryData) => [...testGalleryData, data]);
          }

          if (acf[i].acf_fc_layout === "banner") {
            setSubTitle(acf[i].second_subtitle);
            setDescription(acf[i].bottom_description);
            setBeforeImage(acf[i].before_image);
            setAfterImage(acf[i].after_image);
          }
          if (acf[i].acf_fc_layout === "gallery") {
            setGallery(acf[i].gallery);
            setIsGallery(true);
          }

          if (acf[i].acf_fc_layout === "comparison-slider") {
            setBeforeImage(acf[i].before_image);
            setAfterImage(acf[i].after_image);
            setIsBefore(true);
          }

          if (acf[i].acf_fc_layout === "comparison_slider_two_in_a_row") {
            setBeforeImage(acf[i].before_image);
            setAfterImage(acf[i].after_image);
            setBeforeImage2(acf[i].before_image_2);
            setAfterImage2(acf[i].after_image_2);
            setTwoInRow(true);
            setContent(acf);
          }
          if (acf[i].acf_fc_layout === "banner") {
            setContent(acf);
            setIsBanner(true);
          }
          if (acf[i].acf_fc_layout === "content") {
            setContent(acf);
            setIsVideo(true);
          }
        }
        setMainTitle(data.title.rendered);
        setLoading(false);
      })
      .catch((e) => console.log(e));
    setCounter((oldValue) => oldValue + 1);
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  useEffect(() => {

      if (isSingleBlock) {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        
      } else {
        projectData();
      }

   


  }, [id, isSingleBlock]);

  const FIRST_IMAGE = {
    imageUrl: beforeImage,
  };
  const SECOND_IMAGE = {
    imageUrl: afterImage,
  };
  const FIRST_IMAGE2 = {
    imageUrl: beforeImage2,
  };
  const SECOND_IMAGE2 = {
    imageUrl: afterImage2,
  };
  const inner_width = window.innerWidth - 200;
  return (
    <div className="single-project">
      {loading && <Loader></Loader>}
      {/* <video className="videoend" autoPlay={true} muted={true} loop>
        <source src={leavingVideo} type="video/mp4" />
      </video> */}

      {checkChildren && (
        <div className="has-choices" id="has-choices">
          {children &&
            children.map((child) => (
              <Link
                className="full-link"
                to={"/single-project/" + child.url.ID}
              >
                <div className="choice">
                  <img
                    className="main-photo"
                    src={child.url.acf.banner}
                    alt=""
                  />
                  <div className="abs-img-project"></div>
                  <p className="project-title">{ReactHtmlParser(child.name)}</p>

                  <div className="hover-div">
                    <div className="hover-fill"></div>
                  </div>
                  <img className="right-arrow" src={rightArrow} alt="" />
                </div>
              </Link>
            ))}
        </div>
      )}
      {!checkChildren && isGallery && (
        <div className="after-choice" id="after-choice">
          <div className="slider-text">
            <div className="text">
              <p className="comfort-3d">{ReactHtmlParser(mainTitle)}</p>

              <p className="name-of-project">{ReactHtmlParser(subTitle)}</p>
              <p className="in-project-desc">{ReactHtmlParser(description)}</p>
            </div>
            <div className="slideri">
              <Swiper
                effect={"coverflow"}
                grabCursor={false}
                slidesPerView={1.6}
                centeredSlides={false}
                watchSlidesProgress={true}
                loop={false}
                loopFillGroupWithBlank={false}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 120,
                  modifier: 10,
                  slideShadows: false,
                }}
                modules={[EffectCoverflow, Pagination]}
                className="swiper-wrapper wrapitswipe swipefix"
              >
                {gallery &&
                  isGallery &&
                  gallery.slice(0, 7).map((gal) => (
                    <SwiperSlide className="slides">
                      <img id="fix-width-img" src={gal.url} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
          {isBefore && (
            <ReactCompareImage
              className="compare-image-two"
              hover={false}
              vertical={false}
              leftImage={FIRST_IMAGE}
              rightImage={SECOND_IMAGE}
            />
          )}
        </div>
      )}
      <div className="other-types">
        {isBanner && (
          <div className="is-banner">
            {content &&
              content.map((cont) => (
                <div className="banner-text-wrap">
               <a href={cont.video_url}>   <img className="banner-image" src={cont.banner} /></a>
               {!isGallery &&
                  <div className="is-banner-text">
               
                    <p className="is-banner-title">
                      {ReactHtmlParser(cont.title)}
                    </p>
                    <p className="is-banner-subtitle">
                      {ReactHtmlParser(cont.second_subtitle)}
                    </p>

                    <p className="is-banner-description">
                      {ReactHtmlParser(cont.bottom_description)}

                    </p>
                  </div>
}
                </div>
              ))}
          </div>
        )}
        {isVideo && (
          <div className="is-video">
            {content &&
              content.map((cont) => (
                <iframe
                  src={"https://player.vimeo.com/video/" + cont.iframe}
                  className="iframe-video"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              ))}
          </div>
        )}
        {isSingleBlock && (
          <div className="is-single-block">

            {testGalleryData && [
              testGalleryData.map((data) => [
                <Swiper
                  pagination={true}
                  modules={[Pagination]}
                  className="mySwiper fix-image margin-mobile"
                >
                  {data.gallery.map((item) => [
                    <SwiperSlide>
                      <img src={item.url} alt="" />
                    </SwiperSlide>,
                  ])}
                </Swiper>,
                <p className="singleBlock-text">{ReactHtmlParser(data.description)}</p>,
              ]),
            ]}
           
          </div>
        )}
        <Masonry columns={cols} spacing={spacingMasonry}>
          {gallery && isGallery && gallery.map((gal) => <img src={gal.url} />)}
        </Masonry>
        {isTwoInRow && (
          <div className="slider-two-in-a-row" style={{ marginTop: "300px" }}>
            {content &&
              content.map((cont) => [
                <ReactCompareImage
                  className="compare-image-two"
                  hover={false}
                  vertical={false}
                  leftImage={cont.before_image}
                  rightImage={cont.after_image}
                />,
                <ReactCompareImage
                  className="compare-image-two"
                  hover={false}
                  vertical={false}
                  leftImage={cont.before_image_2}
                  rightImage={cont.after_image_2}
                />,
              ])}
          </div>
        )}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default SingleProject;
