import React from "react";
import "./Header.scss";
import govoriLogo from "../../assets/images/GOVORI_logo.png";
import govoriMenu from "../../assets/images/GOVORI_menu.png";
import govoriView from "../../assets/images/GOVORI-view.svg";
import blueDot from "../../assets/images/blue-dot.svg";

import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import Cursor from "../Cursor/Cursor";
import { useLocation } from "react-router-dom";
import { useState } from "react";
export default function Header() {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");
  let isHome = false;
  let isServices = false;
  let isProjects = false;
  let isAbout = false;
  let isChoice = false;
  if (splitLocation[1] === "/home") {
    isHome = true;
  }
  if (splitLocation[1] === "services") {
    isServices = true;
  }
  if (splitLocation[1] === "projects") {
    isProjects = true;
  }
  if (splitLocation[1] === "about") {
    isAbout = true;
  }
  if (splitLocation[1] === "") {
    isChoice = true;
  }

  window.onscroll = function (event) {
    var scroll = window.pageYOffset;
    if (scroll > 20) {
      document.getElementById("header").style.backgroundColor =
        "rgba(0, 0, 0, 0.7)";
      document.getElementById("header").style.boxShadow =
        "0px 5px 15px -14px #000000";
    } else {
      document.getElementById("header").style.backgroundColor = "transparent";
      document.getElementById("header").style.boxShadow =
        "0px 0px 0px 0px #000000";
    }
  };

  return (
    <div>
      <div class="header" id="header">
        <div class="menu" onMouseDown={toggleMenuDesktop}>
          <img style={{ marginTop: "-5px" }} src={govoriMenu} alt="" />
          <p>Menu</p>
        </div>
        <Link to="/home" onClick={hitGovori}>
          <div class="logo">
            <img src={govoriLogo} alt="" />
          </div>
        </Link>
        <Link to={"/projects"}>
          <div class="view-projects" onMouseDown={hitGovori}>
            <img
              className="view-projects-img"
              style={{ marginTop: "0px" }}
              src={govoriView}
              alt=""
            />
            <p>View projects</p>
          </div>
        </Link>
      </div>

      <div id="opened-menu" className="opened-menu">
        <div className="menu-close" onClick={hitGovori}></div>
        <div className="menu-mobile">
          <Link to="/home" onClick={toggleMenuLinks}>
            {" "}
            <p className={isHome ? "" : "active"}>
              {isHome && (
                <img
                  src={blueDot}
                  id="menuDot"
                  className="cursorivogel active-cursor"
                  alt=""
                />
              )}{" "}
              home
            </p>{" "}
          </Link>
          <Link to="/services" onClick={toggleMenuLinks}>
            {" "}
            <p className={isServices ? "" : "active"}>
              {isServices && (
                <img
                  src={blueDot}
                  id="menuDot"
                  className="cursorivogel active-cursor"
                />
              )}{" "}
              services
            </p>{" "}
          </Link>
          <Link to="/projects" onClick={toggleMenuLinks}>
            <p className={isProjects ? "" : "active"}>
              {isProjects && (
                <img
                  src={blueDot}
                  id="menuDot"
                  className="cursorivogel active-cursor"
                />
              )}{" "}
              projects
            </p>{" "}
          </Link>
          <Link to="/about" onClick={toggleMenuLinks}>
            <p className={isAbout ? "" : "active"}>
              {isAbout && (
                <img
                  src={blueDot}
                  id="menuDot"
                  className="cursorivogel active-cursor"
                />
              )}{" "}
              about
            </p>{" "}
          </Link>
          <Link to="/contact" onClick={toggleMenuLinks}>
            <p className="kontakti"> contact</p>{" "}
          </Link>
        </div>
        <div className="abs-pos-show" id="cool-header"></div>
        <div className="links"></div>
        <div className="circle" id="circle">
          <img src={blueDot} id="doti" alt="" />
          <Link to="/home">
            {" "}
            <div
              onClick={toggleMenuLinks}
              id="home-link"
              onMouseOver={hoverHome}
            >
              home
            </div>
          </Link>
          <Link to="/services">
            {" "}
            <div
              onClick={toggleMenuLinks}
              id="services-link"
              onMouseOver={hoverServices}
            >
              services
            </div>
          </Link>
          <Link to="/projects">
            {" "}
            <div
              onClick={toggleMenuLinks}
              id="projects-link"
              onMouseOver={hoverProjects}
            >
              projects
            </div>
          </Link>
          <Link to="/about">
            {" "}
            <div
              onClick={toggleMenuLinks}
              id="about-link"
              onMouseOver={hoverAbout}
            >
              about
            </div>
          </Link>
          <Link to="/contact">
            {" "}
            <div
              onClick={toggleMenuLinks}
              id="contact-link"
              onMouseOver={hoverContact}
            >
              contact
            </div>
          </Link>
        </div>
      </div>
    </div>
  );

  function hoverHome() {
    document.getElementById("doti").style.top = "51.3%";
    document.getElementById("doti").style.right = "-1.75%";
  }
  function hoverServices() {
    document.getElementById("doti").style.top = "58.3%";
    document.getElementById("doti").style.right = "-0.8%";
  }
  function hoverProjects() {
    document.getElementById("doti").style.top = "65.7%";
    document.getElementById("doti").style.right = "1.3%";
  }
  function hoverAbout() {
    document.getElementById("doti").style.top = "72.5%";
    document.getElementById("doti").style.right = "4.4%";
  }
  function hoverContact() {
    document.getElementById("doti").style.top = "79.5%";
    document.getElementById("doti").style.right = "9.1%";
  }
  function toggleMenuLinks() {
    document
      .getElementById("opened-menu")
      .classList.toggle("menutoggledesktop");
    if (
      document
        .getElementById("opened-menu")
        .classList.contains("menutoggledesktop")
    ) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }
  }
  function hitGovori() {
    document
      .getElementById("opened-menu")
      .classList.remove("menutoggledesktop");
    if (
      document
        .getElementById("opened-menu")
        .classList.contains("menutoggledesktop")
    ) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }
  }
  function toggleMenuDesktop() {
    document
      .getElementById("opened-menu")
      .classList.toggle("menutoggledesktop");
    if (
      document
        .getElementById("opened-menu")
        .classList.contains("menutoggledesktop")
    ) {
      document.body.classList.add("lock-scroll");
    } else {
      document.body.classList.remove("lock-scroll");
    }
    // setTimeout(() => {
    //   document.getElementById("circle").style.display = "block";

    // }, 400);

    setTimeout(() => {
      document.getElementById("cool-header").style.marginTop = "50%";
    }, 300);
    setTimeout(() => {
      document.getElementById("cool-header").style.width = "0%";
    }, 400);

    setTimeout(() => {
      document.getElementById("home-link").style.opacity = "1";
    }, 500);
    setTimeout(() => {
      document.getElementById("services-link").style.opacity = "1";
    }, 1100);
    setTimeout(() => {
      document.getElementById("projects-link").style.opacity = "1";
    }, 1700);
    setTimeout(() => {
      document.getElementById("about-link").style.opacity = "1";
    }, 2300);
    setTimeout(() => {
      document.getElementById("contact-link").style.opacity = "1";
    }, 2900);
  }

  var cursori = document.getElementById("cursori");
  var cursoriinner = document.querySelector(".cursori2");
  var a = document.querySelectorAll("a");

  document.addEventListener("mousemove", function (e) {
    var x = e.clientX;
    var y = e.clientY;
    cursori.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
  });
}
