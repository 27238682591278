import React from 'react';
import "./ProjectsPage.scss"
import Footer from '../../components/Footer/Footer';
import {useParams } from 'react-router-dom';
import Service from "../../services/general";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import Loader from '../../components/loader/Loader';
const ProjetsPage = () => {
    const {id} = useParams();
    const service = new Service();

    const [data, setData] = useState([]);
  
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);


    const projectData = () => {
        service
          .getProjectHome()
          .then((res) => {
            setProjects(res.data);
            setLoading(false);
          })
          .catch((e) => console.log(e));
      };
      useEffect(() => {
        setProjects(projectData);
    
    
      }, []);
     
    return (
        <div className='projects-page'>
          {loading && <Loader></Loader>}
            <div className="projects-page-wrapper">
       
            {projects &&
          projects.map((project) => (
 

      

         <div className="project-page-project">
       <Link to={'/single-project/' + project.id}> <img src={project.acf.banner} alt="" /></Link> 
         <p className='d3-feature'> {ReactHtmlParser(project.title.rendered)}</p>


         {/* <p className='project-info'> {ReactHtmlParser(project.acf.subtitle)}</p> */}
     </div>



          ))}
            </div>
            <Footer></Footer>
        </div>
    );
}

export default ProjetsPage;
