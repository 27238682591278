import axios from "axios";
const api_url = `https://govoristudios.com/new/back/wp-json/wp/v2/`;


const headers = {
  "Content-Type": "application/json",
  "X-Api-Key": "iX2mZhkuCyyrd6T2yvyESkMFK69X4Bdd",
};

class Service {
  getHome = () => {
    return axios.get(
      api_url +
        'pages?slug=home',
      //  { headers }
    );
  };
  getProject = (id) => {
    return axios.get(
      api_url +
      'posts?include=' +`${id}`,
      // { headers }

    )
  }
  getAbout = () => {
    return axios.get(
      api_url +
      'pages?slug=about',
      // { headers }

    )
  }
  getFooter = () => {
    return axios.get(
      api_url +
      'pages?slug=footer',
      // { headers }

    )
  }
  getServices = () => {
    return axios.get(
      api_url +
      'pages?slug=services',
      // { headers }

    )
  }
  getProjectHome = () => {
    return axios.get(
      api_url +
      'posts?parent=0',
      // { headers }

    )
  }
}




export default Service;
