import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import SingleProject from "./pages/Single-Project/Single-Project";
import ProjetsPage from "./pages/Projects/ProjetsPage";
import About from "./pages/About/About";
import Cursor from "./components/Cursor/Cursor";
import ScrollToTop from "./components/ScrollTop/ScrollTop";
import Services from "./pages/Services/Services";
import Loader from "./components/loader/Loader";
import Footer from "./components/Footer/Footer";
import Sites from "./components/Sites/Sites";
function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/single-project/:id" element={<SingleProject />} />
        <Route path="/projects" element={<ProjetsPage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Footer />} />
      </Routes>
      <Cursor></Cursor>
      <ScrollToTop />

      {/* <Footer></Footer> */}
    </div>
  );
}

export default App;
